@use '~@wtw/company-portal/lib/styles/App';
@use "@wtw/plexus-ui/dist/scss/tokens" as *;
@use '@carbon/react/scss/themes' as themes;
@use '@carbon/react/scss/theme' as theme;

.cds--header {
    @include theme.theme(themes.$white, (
        border-interactive: $plx-color-customer-snelandia-purple-600,
    ));
    --cds-background: #{$plx-color-customer-snelandia-yellow-100};
    --cds-border-subtle: #{$plx-color-default-neutral-gray-350};
    --cds-text-secondary: #{$plx-color-default-neutral-gray-980};
    --cds-background-hover: rgba(141, 141, 141, 0.15);
    --cds-background-active: rgba(141, 141, 141, 0.25);
    --cds-skeleton-element: #{$plx-color-default-neutral-gray-350};
    --cds-skeleton-background: #{$plx-color-default-neutral-gray-250};

    border-bottom: 1px solid $plx-color-default-neutral-gray-250;
    .header-overflow-menu {
        color: $plx-color-default-neutral-gray-980;
        --cds-layer: var(--cds-background-selected);
        --cds-layer-hover: var(--cds-background-hover);
        svg { fill: $plx-color-default-neutral-gray-980; }
    }
    .cds--header__name img {
        margin-bottom: 4px;
    }
}

.cds--side-nav {
    @include theme.theme(themes.$white, (
            border-interactive: $plx-color-customer-snelandia-purple-600,
    ));
}

:root {
    --cds-button-primary: #{$plx-color-customer-snelandia-purple-700};
    --cds-button-primary-hover: #{$plx-color-customer-snelandia-purple-800};
    --cds-button-primary-active: #{$plx-color-customer-snelandia-purple-900};
    --cds-interactive: #{$plx-color-customer-snelandia-purple-600};
    --cds-border-interactive: #{$plx-color-customer-snelandia-purple-600};
    --cds-link-primary: #{$plx-color-customer-snelandia-purple-700};
    --cds-link-primary-hover: #{$plx-color-customer-snelandia-purple-800};
}

.cds--modal {
    .cds--btn--secondary {
        color: #{$plx-color-customer-snelandia-purple-700};
        &:hover { color: #{$plx-color-customer-snelandia-purple-800}; }
        &:active { color: #{$plx-color-customer-snelandia-purple-800}; }
    }
}

.cds--side-nav {
    --nav-link-hover-color: #{$plx-color-customer-snelandia-purple-50};
    --nav-link-active-color: #{$plx-color-customer-snelandia-purple-150};
    --nav-link-current-border-color: #{$plx-color-customer-snelandia-purple-600};
    --nav-link-external-color: #{$plx-color-customer-snelandia-purple-700};
    --nav-link-external-hover-color: #{$plx-color-customer-snelandia-purple-800};
    --nav-link-external-active-color: #{$plx-color-customer-snelandia-purple-900};
}